import axios from 'axios';

const url = 'https://api.homologacao.clubdosmilionarios.com.br';

const api = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});
api.authURL = `${url}/api/v1/api-token-auth/adm/`;
api.authRefreshURL = `${url}/api/v1/api-token-refresh/`;
api.url = url;

export default api;
