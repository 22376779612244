/* eslint-disable class-methods-use-this */
import api from '../API/config';

class AuthService {
  async login(user) {
    const body = {
      email: user.email,
      password: user.password,
    };
    try {
      const { data } = await api.post(`${api.authURL}`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
}

export default new AuthService();
